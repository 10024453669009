import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Layout from "../../components/layout"
import SEO from "/src/components/seo"
import CustomAccordion from "../../components/Accordion"
import { Link } from "gatsby"
import "../../components/v2/MlopsPage/Mlops.scss"

const bannerImg = `${process.env.GATSBY_CDN_CONFIG}/v2/mlops-bg.svg`
const Section1Img = `${process.env.GATSBY_CDN_CONFIG}/v2/mlops-bg-s1.png`
const Section2Img = `${process.env.GATSBY_CDN_CONFIG}/v2/mlops-bg-s2.png`
const DataManagementImg = `${process.env.GATSBY_CDN_CONFIG}/v2/data-management-img.png`
const CollaborationGovernanceImg = `${process.env.GATSBY_CDN_CONFIG}/v2/collaboration-governance.png`
const FeedbackLoopsImg = `${process.env.GATSBY_CDN_CONFIG}/v2/feedback-loops.png`
const ModelDeploymentImg = `${process.env.GATSBY_CDN_CONFIG}/v2/model-deployment.png`
const ModelTrainingImg = `${process.env.GATSBY_CDN_CONFIG}/v2/model-training.png`
const MonitoringImg = `${process.env.GATSBY_CDN_CONFIG}/v2/monitoring.png`

const ListIcon = `${process.env.GATSBY_CDN_CONFIG}/v2/list-icon.png`
const MlopsDevelopmentImg = `${process.env.GATSBY_CDN_CONFIG}/v2/mlops-development.png`
const MlopsconsultingImg = `${process.env.GATSBY_CDN_CONFIG}/v2/mlops-consulting.png`

const UnionImg = `${process.env.GATSBY_CDN_CONFIG}/v2/union.png`
const element1Icon = `${process.env.GATSBY_CDN_CONFIG}/v2/section-3__block-3__icon/element-1__icon.png`
const element2Icon = `${process.env.GATSBY_CDN_CONFIG}/v2/section-3__block-3__icon/element-2__icon.png`
const element3Icon = `${process.env.GATSBY_CDN_CONFIG}/v2/section-3__block-3__icon/element-3__icon.png`
const element4Icon = `${process.env.GATSBY_CDN_CONFIG}/v2/section-3__block-3__icon/element-4__icon.png`
const DividerImg = `${process.env.GATSBY_CDN_CONFIG}/v2/divider.png`

const Session4BgImg = `${process.env.GATSBY_CDN_CONFIG}/v2/section-4/bg__img.png`
const Session4Icon1 = `${process.env.GATSBY_CDN_CONFIG}/v2/section-4/icon-1.png`
const Session4Icon2 = `${process.env.GATSBY_CDN_CONFIG}/v2/section-4/icon-2.png`
const Session4Icon3 = `${process.env.GATSBY_CDN_CONFIG}/v2/section-4/icon-3.png`
const Session4Icon4 = `${process.env.GATSBY_CDN_CONFIG}/v2/section-4/icon-4.png`

// const Session5BgImg = `${process.env.GATSBY_CDN_CONFIG}/v2/section-5/bg__img.png`
const Session5BgImg = `${process.env.GATSBY_CDN_CONFIG}/v2/section-5/bg_img.png`
const Session5Icon1 = `${process.env.GATSBY_CDN_CONFIG}/v2/section-5/icon-1.png`
const Session5Icon2 = `${process.env.GATSBY_CDN_CONFIG}/v2/section-5/icon-2.png`
const Session5Icon3 = `${process.env.GATSBY_CDN_CONFIG}/v2/section-5/icon-3.png`
const Session5Icon4 = `${process.env.GATSBY_CDN_CONFIG}/v2/section-5/icon-4.png`

const Session6Data =
  [
    {
      title: "What makes ML operations different from other software applications?",
      description: "Unlike other software applications, MLOps is data-driven, dynamic, scalable, collaborative, and compliant. It’s a complex and dynamic field that requires specialized tools and processes to manage the entire machine-learning lifecycle. MLOps platforms provide a way for organizations to build and manage machine learning applications at scale while ensuring that models are accurate, reliable, and compliant.",
    },
    {
      title: "How can we control costs for ML applications and platforms?",
      description: "Controlling costs for ML applications and platforms can be a significant challenge, especially as data sets and models grow in size and complexity. You should optimize your hardware, use cost-effective data storage, automate processes, monitor and optimize resources, and use open-source tools. ",
    },
    {
      title: "How much does Neurond MLOps cost?",
      description: "It depends on your organization’s ML requirements and scalability. Our cost varies based on platforms, infrastructure, and applications under support. Contact us for more details on our pricing packages.",
    },
  ]

const bannerData = {
  title: ['MLOps for AI Vision', 'Train Better, Faster, and More Efficient!'],
  content: "Our MLOps empower your AI engineers, let you bring your ML models to production, launch products, and updates faster. Whether you're an enterprise looking to automate your processes or a startup in need of a scalable AI solution, our MLOps AI Service can help!",
}

const MlopsPage = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title={t("mlops")} />
      <div className="margin-bot">
        <div
          className="v2-banner"
          style={{ backgroundImage: `url(${bannerImg})` }}
        >
          <div className="overlay d-flex">
            <div className="v2-banner-content-wrapper wrapper">
              <div className="v2-banner-content">
                <div className="v2-banner-content__wrapper">
                  <h1 className="v2-banner-content__title pd-l-24">MLOps for AI Vision</h1>
                  <p className="v2-banner-content__subtitle pd-l-24">Train Better, Faster, and More Efficient!</p>
                  <p className="v2-banner-content__text pd-l-24">
                    Our MLOps empower your AI engineers, let you bring your ML models to production, launch products, and updates faster. Whether you're an enterprise looking to automate your processes or a startup in need of a scalable AI solution, our MLOps AI Service can help!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-1" style={{ backgroundImage: `url(${Section1Img})` }}>
          <div className="section-1-content-wrapper wrapper">
            <div className="section-1-content">
              <div className="section-1-left-content">
                <h2 className="section-1-left-content__title pd-b-24">What is MLOps?</h2>
                <p className="section-1-left-content__text pd-b-24">MLOps (DevOps for Machine Learning) is a set of principles and practices to enable the automated, continuous delivery and updating of machine learning models. It combines the practices of DevOps and Machine Learning to enable teams to develop, train, and deploy ML models faster, with improved accuracy and confidence.</p>
                <Link
                  className="mlops__btn section-1-left-content__btn"
                  to="/contact"
                >
                  Talk to Our Expert!
                </Link>
              </div>
              <div className="section-1-right-content">
                <div className="overlay-1"></div>
                <div className="overlay-2"></div>
                <img src="https://neurondstorage.blob.core.windows.net/neurondasset/v2/mlops-s1.svg" alt="What is MLOps?"/>
              </div>
            </div>
          </div>
        </div>

        <div className="section-2" style={{ backgroundImage: `url(${Section2Img})` }}>
          <div className="section-2-content-wrapper wrapper">
            <div className="section-2-content">
              <h2 className="section-2-content__title">Components of MLOps</h2>
              <div className="section-2-content__cards cards d-flex flex-wrap">
                <div className="flex-col d-flex flex-column gap-30">
                  <div className="component-card">
                    <div className="component-card__body">
                      <img className="component-card__img" src={DataManagementImg} alt="Data Management"/>
                      <h5 className="component-card__title">Data Management</h5>
                      <p className="component-card__text">Ensure that data, the foundation of MLOps, is managed effectively, including data storage, data cleaning, data preprocessing, and data versioning.</p>
                    </div>
                  </div>
                  <div className="component-card">
                    <div className="component-card__body">
                      <img className="component-card__img" src={ModelDeploymentImg} alt="Model Deployment"/>
                      <h5 className="component-card__title">Model Deployment</h5>
                      <p className="component-card__text">Involve deploying the trained model in a production environment, making it available for use by end-users. It requires selecting the appropriate infrastructure, containerizing the model, and deploying it to a production environment.</p>
                    </div>
                  </div>

                  <div className="component-card">
                    <div className="component-card__body">
                      <img className="component-card__img" src={FeedbackLoopsImg} alt="Feedback Loops"/>
                      <h5 className="component-card__title">Feedback Loops</h5>
                      <p className="component-card__text">Allow MLOps teams to continuously improve the model's performance. Feedback can come from end-users, data scientists, or automated systems.</p>
                    </div>
                  </div>
                </div>
                <div className="flex-col d-flex flex-column gap-30">
                  <div className="component-card">
                    <div className="component-card__body">
                      <img className="component-card__img" src={ModelTrainingImg} alt="Model Training"/>
                      <h5 className="component-card__title">Model Training</h5>
                      <p className="component-card__text">Include creating, testing, and refining ML models to improve their accuracy and performance. You have to select the appropriate algorithm, tune its hyperparameters, and evaluate the model's performance against various metrics.</p>
                    </div>
                  </div>
                  <div className="component-card">
                    <div className="component-card__body">
                      <img className="component-card__img" src={MonitoringImg} alt="Monitoring"/>
                      <h5 className="component-card__title">Monitoring</h5>
                      <p className="component-card__text">Ensure the model is performing as expected after deployment. Various metrics need trackings such as accuracy, latency, and throughput, and triggering alerts.</p>
                    </div>
                  </div>
                  <div className="component-card">
                    <div className="component-card__body">
                      <img className="component-card__img" src={CollaborationGovernanceImg} alt="Collaboration & Governance"/>
                      <h5 className="component-card__title">Collaboration & Governance</h5>
                      <p className="component-card__text">Make sure that all stakeholders (data scientists, developers, and business stakeholders) are aligned and work towards the same goals. This component involves creating clear guidelines and processes for collaboration, version control, and documentation.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-3">
          <div className="section-3-content-wrapper wrapper">
            <div className="section-3-content">
              <h2 className="section-3-content__title">What Does Neurond Do?</h2>
              <p className="section-3-content__text">We offer both MLOps consulting and MLOps development for businesses and organization.</p>
              <div className="section-3-content__block d-flex flex-column">
                <div className="block-1">
                  <h3 className="block-1__title">MLOps Consulting</h3>
                  <div className="block-1__content">
                    <img className="block-1__img" src={MlopsconsultingImg} alt="MLOps Consulting"/>
                    <ul>
                      <li>
                        <img src={ListIcon} alt="List Icon"/>
                        <span>Scale and avoid potential risks while implementing changes that lead your business to growth.</span>
                      </li>
                      <li>
                        <img src={ListIcon} alt="List Icon"/>
                        <span>Analyze the strengths and limitations of the current solution, evaluate the effectiveness of the model, and make recommendations for improvement.</span>
                      </li>
                      <li>
                        <img src={ListIcon} alt="List Icon"/>
                        <span>Select and implement model training, scaling techniques, and problem-solving methodologies.</span>
                      </li>
                      <li>
                        <img src={ListIcon} alt="List Icon"/>
                        <span>Choosing and adopting approaches for model training, scaling methods and problem-solving processes.</span>
                      </li>
                      <li>
                        <Link
                          className="mlops__btn block-1__content__btn"
                          to="/contact"
                        >
                          Contact Us Now
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="block-2">
                  <h3 className="block-2__title">MLOps Development</h3>
                  <div className="block-2__content">
                    <img className="block-2__img" src={MlopsDevelopmentImg} alt="MLOps Development"/>
                    <ul>
                      <li>
                        <img src={ListIcon} alt="List Icon"/>
                        <span>Concentrate on the implementation itself and carefully organizing the system.</span>
                      </li>
                      <li>
                        <img src={ListIcon} alt="List Icon"/>
                        <span>Adjust AI solutions to meet the requirements of a certain system or customer. Enhance the solution by focusing on the needs and objectives of the client.</span>
                      </li>
                      <li>
                        <Link
                          className="mlops__btn block-2__content__btn"
                          to="/contact"
                        >
                          Upgrade your team!
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-3-1" >
          <img class="section-3-1__union__img" src={UnionImg} alt="union background"></img>
          <div className="section-3-1-content-wrapper wrapper">
            <div className="section-3-1-content">
              <div className="element element-1">
                <p className="element-1__header">So I guess sending an email would've worked better indeed. And I got logged out on three devices simultaneously.</p>
                <div className="element__content">
                  <div className="left-one">
                    <img className="element__icon" src={element1Icon} alt="Automate your ML workflow" />
                    <p>Automate your ML workflow</p>
                  </div>
                  <div className="divider"></div>
                    <p className="right-one">Neurond MLOps manage your entire machine learning pipeline, starting with data preparation to model training and then model deployment. We ease the machine learning integration into your existing workflows by automating the deployment and scaling of your models.</p>
                </div>
              </div>
              <div className="element element-2">
                <div className="element__content">
                  <div className="left-one">
                    <img className="element__icon" src={element2Icon} alt="Increase your ML team’s efficiency"/>
                    <p>Increase your ML
                      team’s efficiency</p>
                  </div>
                  <div className="divider"></div>
                    <p className="right-one">Our MLOps AI Service provides a centralized platform for managing and collaborating on machine learning projects. With built-in version control and collaboration tools, your team can work more efficiently and effectively.</p>
                </div>
              </div>
              <div className="element element-3">
                <div className="element__content">
                  <div className="left-one">
                    <img className="element__icon" src={element3Icon} alt="Ensure the reliability and reproducibility of your models"/>
                    <p id="text-left-3">Ensure the reliability and reproducibility of your models</p>
                  </div>
                  <img className="divider" src={DividerImg} alt="Line Divider"/>
                  <p className="right-one">Our platform includes tools for tracking and monitoring your models in production, making it easy to identify and address issues. We also provide tools for reproducibility, ensuring that your models can be recreated and tested over time.</p>
                </div>
              </div>
              <div className="element element-4">
                <div className="element__content">
                  <div className="left-one">
                    <img className="element__icon" src={element4Icon} alt="Scale your machine learning infrastructure"/>
                    <p>Scale your machine learning infrastructure</p>
                  </div>
                  <div className="divider"></div>
                    <p className="right-one">Neurond’s MLOps AI Service is designed to work with any cloud provider, making it easy to scale your machine learning infrastructure as your needs evolve.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-4">
          <div className="section-4__title">
            <h2>MLOPs Across Industries</h2>
            <p>A wide range of industries can leverage the power of our MLOps services.</p>
          </div>
          <div className="section-4__bg" style={{ backgroundImage: `url(${Session4BgImg})` }}>
            <div className="section-4-content-wrapper wrapper">
              <div className="section-4__content d-flex flex-wrap">
                <div className="section-4__card">
                  <img className="icon__img" src={Session4Icon1} alt="Finance"/>
                  <h2>Finance</h2>
                  <p>Help financial institutions develop fraud detection models, credit risk assessment models, and predictive analytics models.</p>
                </div>

                <div className="section-4__card">
                  <img className="icon__img" src={Session4Icon2} alt="Manufacturing"/>
                  <h2>Manufacturing</h2>
                  <p>Allow manufacturers to develop predictive maintenance models, quality control models, and supply chain optimization models.</p>
                </div>

                <div className="section-4__card">
                  <img className="icon__img" src={Session4Icon3} alt="E-commerce"/>
                  <h2>E-commerce</h2>
                  <p>Enable e-commerce companies to build recommendation systems, pricing optimization models, and demand forecasting models.</p>
                </div>

                <div className="section-4__card" alt="Fintech">
                  <img className="icon__img" src={Session4Icon4} alt="Fintech" />
                  <h2>FinTech</h2>
                  <p>Automate and support operations with sensitive data and process tons of data simultaneously.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section-5" style={{ backgroundImage: `url(${Session5BgImg})` }}>
          <div className="section-5-content-wrapper wrapper">
            <div class="section-5__title">
              <h2>Why Choose Neurond MLOps Platform</h2>
              <p>Our enterprise ready MLOps teams have a proven record of stability and performance.</p>
            </div>
            <div class="section-5__content d-flex flex-wrap">
              <div class="section-5__card">
                <div class="card__wrapper">
                  <img class="icon__img" src={Session5Icon1} alt="Proactive monitoring"/>
                  <h3>Proactive monitoring</h3>
                  <p>Identify issues arising in infrastructure.</p>
                </div>
              </div>
              <div class="section-5__card">
                <div class="card__wrapper">
                  <img class="icon__img" src={Session5Icon2} alt="24/7 Support Icon"/>
                  <h3>24/7 support</h3>
                  <p>Provide world-class support during business hours or you need 24x7x365 availability.</p>
                </div>
              </div>
              <div class="section-5__card">
                <div class="card__wrapper">
                  <img class="icon__img" src={Session5Icon3} alt="Incident management"/>
                  <h3>Incident management</h3>
                  <p>Restore services quickly and ensure issues don’t recur.</p>
                </div>
              </div>
              <div class="section-5__card">
                <div class="card__wrapper">
                  <img class="icon__img" src={Session5Icon4} alt="Clear communication"/>
                  <h3>Clear communication</h3>
                  <p>With status reporting, weekly technical reviews, and quarterly business reviews, you can keep track of performance and expenses.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section-6">
          <div className="section-6-content-wrapper wrapper">
            <div class="section-6__title">
              <h3>FAQs</h3>
              <p>Got questions about Neurond MLOps? We've got answers.</p>
            </div>
            <div class="section-6__content">
              <CustomAccordion data={Session6Data} />
              <div class="other-questions-element d-flex flex-column justify-content-center">
                <p>Have other questions?</p>
                <Link
                  className='mlops__btn btn-reach-out'
                  to="/contact"
                >
                  Reach out to us!
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MlopsPage
